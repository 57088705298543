var data = [{
    english_name: "United States",
    chinese_name: "美国",
    country_code: "US",
    phone_code: "1",
    country_img: "/assets/country/US.png"
},
{
    english_name: "China",
    chinese_name: "中国",
    country_code: "CN",
    phone_code: "86",
    country_img: "/assets/country/CN.png"
},
{
    english_name: "United Kingdom",
    chinese_name: "英国",
    country_code: "GB",
    phone_code: "44",
    country_img: "/assets/country/GB.png"
},
{
    english_name: "France",
    chinese_name: "法国",
    country_code: "FR",
    phone_code: "33",
    country_img: "/assets/country/FR.png"
},
{
    english_name: "Japan",
    chinese_name: "日本",
    country_code: "JP",
    phone_code: "81",
    country_img: "/assets/country/JP.png"
},
{
    english_name: "Spain",
    chinese_name: "西班牙",
    country_code: "ES",
    phone_code: "34",
    country_img: "/assets/country/ES.png"
}, {
    english_name: "Egypt",
    chinese_name: "埃及",
    country_code: "EG",
    phone_code: "20",
    country_img: "/assets/country/EG.png"
},
{
    english_name: "Vietnam",
    chinese_name: "越南",
    country_code: "VN",
    phone_code: "84",
    country_img: "/assets/country/VN.png"
},
{
    english_name: "Russia",
    chinese_name: "俄罗斯",
    country_code: "RU",
    phone_code: "7",
    country_img: "/assets/country/RU.png"
}, {
    english_name: "Peru",
    chinese_name: "秘鲁",
    country_code: "PE",
    phone_code: "51",
    country_img: "/assets/country/PE.png"
}, {
    english_name: "Argentina",
    chinese_name: "阿根廷",
    country_code: "AR",
    phone_code: "54",
    country_img: "/assets/country/AR.png"
}, {
    english_name: "Afghanistan",
    chinese_name: "阿富汗",
    country_code: "AF",
    phone_code: "93",
    country_img: "/assets/country/AF.png"
}, {
    english_name: "Andorra",
    chinese_name: "安道尔",
    country_code: "AD",
    phone_code: "376",
    country_img: "/assets/country/AD.png"
}, {
    english_name: "Australia",
    chinese_name: "澳大利亚",
    country_code: "AU",
    phone_code: "61",
    country_img: "/assets/country/AU.png"
}, {
    english_name: "Austria",
    chinese_name: "奥地利",
    country_code: "AT",
    phone_code: "43",
    country_img: "/assets/country/AT.png"
}, {
    english_name: "Bangladesh",
    chinese_name: "孟加拉国",
    country_code: "BD",
    phone_code: "880",
    country_img: "/assets/country/BD.png"
}, {
    english_name: "Belarus",
    chinese_name: "白俄罗斯",
    country_code: "BY",
    phone_code: "375",
    country_img: "/assets/country/BY.png"
}, {
    english_name: "Belgium",
    chinese_name: "比利时",
    country_code: "BE",
    phone_code: "32",
    country_img: "/assets/country/BE.png"
}, {
    english_name: "Bhutan",
    chinese_name: "不丹",
    country_code: "BT",
    phone_code: "975",
    country_img: "/assets/country/BT.png"
}, {
    english_name: "Brazil",
    chinese_name: "巴西",
    country_code: "BR",
    phone_code: "55",
    country_img: "/assets/country/BR.png"
}, {
    english_name: "Brunei",
    chinese_name: "文莱",
    country_code: "BN",
    phone_code: "673",
    country_img: "/assets/country/BN.png"
}, {
    english_name: "Bulgaria",
    chinese_name: "保加利亚",
    country_code: "BG",
    phone_code: "359",
    country_img: "/assets/country/BG.png"
}, {
    english_name: "Cambodia",
    chinese_name: "柬埔寨",
    country_code: "KH",
    phone_code: "855",
    country_img: "/assets/country/KH.png"
}, {
    english_name: "Cameroon",
    chinese_name: "喀麦隆",
    country_code: "CM",
    phone_code: "237",
    country_img: "/assets/country/CM.png"
}, {
    english_name: "Canada",
    chinese_name: "加拿大",
    country_code: "CA",
    phone_code: "001",
    country_img: "/assets/country/CA.png"
}, {
    english_name: "Central African Republic",
    chinese_name: "中非共和国",
    country_code: "CF",
    phone_code: "236",
    country_img: "/assets/country/CF.png"
}, {
    english_name: "Chile",
    chinese_name: "智利",
    country_code: "CL",
    phone_code: "56",
    country_img: "/assets/country/CL.png"
}, {
    english_name: "Colombia",
    chinese_name: "哥伦比亚",
    country_code: "CO",
    phone_code: "57",
    country_img: "/assets/country/CO.png"
}, {
    english_name: "Republic Of The Congo",
    chinese_name: "刚果共和国",
    country_code: "CG",
    phone_code: "242",
    country_img: "/assets/country/CG.png"
}, {
    english_name: "Cuba",
    chinese_name: "古巴",
    country_code: "CU",
    phone_code: "53",
    country_img: "/assets/country/CU.png"
}, {
    english_name: "Czech",
    chinese_name: "捷克",
    country_code: "CZ",
    phone_code: "420",
    country_img: "/assets/country/CZ.png"
}, {
    english_name: "Denmark",
    chinese_name: "丹麦",
    country_code: "DK",
    phone_code: "45",
    country_img: "/assets/country/DK.png"
}, {
    english_name: "Ethiopia",
    chinese_name: "埃塞俄比亚",
    country_code: "ET",
    phone_code: "251",
    country_img: "/assets/country/ET.png",
},
{
    english_name: "Fiji",
    chinese_name: "斐济",
    country_code: "FJ",
    phone_code: "679",
    country_img: "/assets/country/FJ.png"
}, {
    english_name: "Finland",
    chinese_name: "芬兰",
    country_code: "FI",
    phone_code: "358",
    country_img: "/assets/country/FI.png"
},
{
    english_name: "Greece",
    chinese_name: "希腊",
    country_code: "GR",
    phone_code: "30",
    country_img: "/assets/country/GR.png"
}, {
    english_name: "Greenland",
    chinese_name: "格陵兰岛",
    country_code: "GL",
    phone_code: "299",
    country_img: "/assets/country/GL.png"
},
{
    english_name: "Hong Kong",
    chinese_name: "中国香港",
    country_code: "HK",
    phone_code: "852",
    country_img: "/assets/country/HK.png"
}, {
    english_name: "Hungary",
    chinese_name: "匈牙利",
    country_code: "HU",
    phone_code: "36",
    country_img: "/assets/country/HU.png"
}, {
    english_name: "Iceland",
    chinese_name: "冰岛",
    country_code: "IS",
    phone_code: "354",
    country_img: "/assets/country/IS.png"
}, {
    english_name: "India",
    chinese_name: "印度",
    country_code: "IN",
    phone_code: "91",
    country_img: "/assets/country/IN.png"
}, {
    english_name: "Indonesia",
    chinese_name: "印度尼西亚",
    country_code: "ID",
    phone_code: "62",
    country_img: "/assets/country/ID.png"
},
{
    english_name: "Israel",
    chinese_name: "以色列",
    country_code: "IL",
    phone_code: "972",
    country_img: "/assets/country/IL.png"
}, {
    english_name: "Italy",
    chinese_name: "意大利",
    country_code: "IT",
    phone_code: "39",
    country_img: "/assets/country/IT.png"
},
{
    english_name: "Germany",
    chinese_name: "德国",
    country_code: "DE",
    phone_code: "49",
    country_img: "/assets/country/DE.png"
},
{
    english_name: "Iran",
    chinese_name: "伊朗",
    country_code: "IR",
    phone_code: "98",
    country_img: "/assets/country/IR.png"
},
{
    english_name: "Jamaica",
    chinese_name: "牙买加",
    country_code: "JM",
    phone_code: "1876",
    country_img: "/assets/country/JM.png"
},
{
    english_name: "Ireland",
    chinese_name: "爱尔兰",
    country_code: "IE",
    phone_code: "353",
    country_img: "/assets/country/IE.png"
},
{
    english_name: "Korea Democratic Rep.",
    chinese_name: "朝鲜",
    country_code: "KP",
    phone_code: "85",
    country_img: "/assets/country/KP.png"
},
{
    english_name: "South Korea",
    chinese_name: "韩国",
    country_code: "KR",
    phone_code: "82",
    country_img: "/assets/country/KR.png"
},
{
    english_name: "Laos",
    chinese_name: "老挝",
    country_code: "LA",
    phone_code: "856",
    country_img: "/assets/country/LA.jpg"
},
{
    english_name: "Mexico",
    chinese_name: "墨西哥",
    country_code: "MX",
    phone_code: "52",
    country_img: "/assets/country/MX.png"
},
{
    english_name: "Malaysia",
    chinese_name: "马来西亚",
    country_code: "MY",
    phone_code: "60",
    country_img: "/assets/country/MY.png"
},
{
    english_name: "Monaco",
    chinese_name: "摩纳哥",
    country_code: "MC",
    phone_code: "377",
    country_img: "/assets/country/MC.png"
},
{
    english_name: "Norway",
    chinese_name: "挪威",
    country_code: "NO",
    phone_code: "47",
    country_img: "/assets/country/NO.png"
},
{
    english_name: "Morocco",
    chinese_name: "摩洛哥",
    country_code: "MA",
    phone_code: "212",
    country_img: "/assets/country/MA.png"
},
{
    english_name: "Myanmar",
    chinese_name: "缅甸",
    country_code: "MM",
    phone_code: "95",
    country_img: "/assets/country/MM.png"
},
{
    english_name: "Netherlands",
    chinese_name: "荷兰",
    country_code: "NL",
    phone_code: "31",
    country_img: "/assets/country/NL.png"
},
{
    english_name: "New Zealand",
    chinese_name: "新西兰",
    country_code: "NZ",
    phone_code: "64",
    country_img: "/assets/country/NZ.png"
},
{
    english_name: "Philippines",
    chinese_name: "菲律宾",
    country_code: "PH",
    phone_code: "63",
    country_img: "/assets/country/PH.png"
},
{
    english_name: "Poland",
    chinese_name: "波兰",
    country_code: "PL",
    phone_code: "48",
    country_img: "/assets/country/PL.png"
},
{

    english_name: "Portugal",
    chinese_name: "葡萄牙",
    country_code: "PT",
    phone_code: "351",
    country_img: "/assets/country/PT.png"
},
{
    english_name: "Pakistan",
    chinese_name: "巴基斯坦",
    country_code: "PK",
    phone_code: "92",
    country_img: "/assets/country/PK.png"
},
{
    english_name: "Qatar",
    chinese_name: "卡塔尔",
    country_code: "QA",
    phone_code: "974",
    country_img: "/assets/country/QA.png"
},
{
    english_name: "Romania",
    chinese_name: "罗马尼亚",
    country_code: "RO",
    phone_code: "40",
    country_img: "/assets/country/RO.png"
},

{
    english_name: "Rwanda",
    chinese_name: "卢旺达",
    country_code: "RW",
    phone_code: "250",
    country_img: "/assets/country/RW.png"
},
{
    english_name: "Saudi Arabia",
    chinese_name: "沙特阿拉伯",
    country_code: "SA",
    phone_code: "966",
    country_img: "/assets/country/SA.png"
},
{
    english_name: "Singapore",
    chinese_name: "新加坡",
    country_code: "SG",
    phone_code: "65",
    country_img: "/assets/country/SG.png"
},
{
    english_name: "Slovakia",
    chinese_name: "斯洛伐克",
    country_code: "SK",
    phone_code: "421",
    country_img: "/assets/country/SK.png"
},
{
    english_name: "South Africa",
    chinese_name: "南非",
    country_code: "ZA",
    phone_code: "27",
    country_img: "/assets/country/ZA.png"
},

{
    english_name: "Sri Lanka",
    chinese_name: "斯里兰卡",
    country_code: "LK",
    phone_code: "94",
    country_img: "/assets/country/LK.png"
},
{
    english_name: "Sweden",
    chinese_name: "瑞典",
    country_code: "SE",
    phone_code: "46",
    country_img: "/assets/country/SE.png"
},
{
    english_name: "Switzerland",
    chinese_name: "瑞士",
    country_code: "CH",
    phone_code: "41",
    country_img: "/assets/country/CH.png"
},
{
    english_name: "Taiwan",
    chinese_name: "中国台湾",
    country_code: "TW",
    phone_code: "886",
    country_img: "/assets/country/TW.png"
},
{
    english_name: "Thailand",
    chinese_name: "泰国",
    country_code: "TH",
    phone_code: "66",
    country_img: "/assets/country/TH.png"
},
{
    english_name: "Timor-Leste",
    chinese_name: "东帝汶",
    country_code: "TL",
    phone_code: "670",
    country_img: "/assets/country/TL.png"
},
{
    english_name: "United Arab Emirates",
    chinese_name: "阿拉伯联合酋长国",
    country_code: "AE",
    phone_code: "971",
    country_img: "/assets/country/AE.png"
},
{
    english_name: "Turkey",
    chinese_name: "土耳其",
    country_code: "TR",
    phone_code: "90",
    country_img: "/assets/country/TR.png"
},

{
    english_name: "Ukraine",
    chinese_name: "乌克兰",
    country_code: "UA",
    phone_code: "380",
    country_img: "/assets/country/UA.png"
},

{
    english_name: "Uruguay",
    chinese_name: "乌拉圭",
    country_code: "UY",
    phone_code: "598",
    country_img: "/assets/country/UY.png"
},
{
    english_name: "Republic of Malta",
    chinese_name: "马耳他",
    country_code: "MT",
    phone_code: "356",
    country_img: "/assets/country/MT.png"
},
{
    english_name: "kuwait",
    chinese_name: "科威特",
    country_code: "KW",
    phone_code: "965",
    country_img: "/assets/country/KW.png"
},
{
    english_name: "oman",
    chinese_name: "阿曼",
    country_code: "OM",
    phone_code: "968",
    country_img: "/assets/country/OM.png"
},
,
{
    english_name: "BH",
    chinese_name: "巴林",
    country_code: "BH",
    phone_code: "973",
    country_img: "/assets/country/BH.svg"
},
{
    english_name: "NG",
    chinese_name: "尼日利亚",
    country_code: "NG",
    phone_code: "234",
    country_img: "/assets/country/NG.svg"
},
{
    english_name: "JOR",
    chinese_name: "约旦",
    country_code: "JOR",
    phone_code: "962",
    country_img: "/assets/country/JOR.png"
},
{
    english_name: "EG",
    chinese_name: "埃及",
    country_code: "EG",
    phone_code: "20",
    country_img: "/assets/country/eg.svg"
},
{
    english_name: "IQ",
    chinese_name: "伊拉克",
    country_code: "IQ",
    phone_code: "964",
    country_img:"/assets/country/iq.svg"
},
];
export default {
    data
}